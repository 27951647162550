import React, { useState, useEffect } from 'react';
import { IoMdCall, IoMdMail, IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, withRouter, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { Img } from 'component/FrameL';

import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import './style.css';

function DesktopNavbar({ CoreData }) {
  const { logo } = CoreData;
  const [isOpen, setIsOpen] = useState(true);
  // const [bookingStart, setBookingStart] = useState(moment(new Date()));
  // const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
  // const [bookingFocusedInput, setBookingFocusedInput] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleScrollClose = () => {
      if (window.scrollY < 50) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<>
    <div className={`fixed top-0 left-0 m-4 z-50 flex items-center`}>
      <button onMouseMove={() => {
        setIsOpen(true);
      }}
        aria-label="Menu"
        className="bg-white p-2 tailwind-block">
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>
    </div>
    <div className={`navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <ul className="xl:container text-sm navbar__content text-center pt-12 pb-8 uppercase mx-auto flex justify-center items-center" >
        <li>
          <Link className={pathname === '/rooms' && 'active'} to="/rooms">
            Rooms
          </Link>
        </li>
        <li>
          <Link className={pathname === '/gallery/main' && 'active'} to="/gallery/main">
            Photo Gallery
          </Link>
        </li>
        <li>
          <Link className={pathname === '/specials' && 'active'} to="/specials">
            Specials & Events
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' && 'active'} to="/dining">
            Dining & Tavern
          </Link>
        </li>
        <li>
          <Link to='/' className='tailwind-block navbar__logo'>
            <Img Image={logo} className=''></Img>
          </Link>
        </li>
        <li>
          <Link className={pathname === '/things-to-do' && 'active'} to="/things-to-do">
            Things to Do
          </Link>
        </li>
        <li>
          <Link className={pathname === '/weddings' && 'active'} to="/weddings">
            Weddings & Meetings
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' && 'active'} to="/about">
            About
          </Link>
        </li>
        <li>
          <Link className={pathname === '/contact' && 'active'} to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
    <div className={`fixed mt-4 mr-4 top-0 right-0 cluster-l z-20`} style={{
      '--cSpace': "var(--s-2)"
    }}>
      <a
        href={`https://bookings.travelclick.com/95899#/guestsandrooms`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Book Now"
        className="text-lg text-white btn btn-primary">
        Book Now
      </a>

      <a
        href={`https://www.opentable.com/restref/client/?rid=218656&restref=218656`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="OpenTable Booking Link"
        className="text-lg text-white btn btn-primary-outline">
        OpenTable
      </a>
    </div>
  </>
  );
}

function MobileNavbar({ CoreData }) {
  const [isOpen, setIsOpen] = useState(false);
  const { logo, logoWide } = CoreData;

  const { pathname } = useLocation();

  return (<div className="fixed top-0 left-0 right-0 z-20">
    <div className={`flex flex-row items-start pointer-events-auto`}>
      <button className="m-4 p-2 bg-black bg-opacity-80" aria-label="Menu" onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-white icon"></IoMdMenu>
      </button>

      <div className='w-full mx-auto'>
        <Link to='/' className='tailwind-block navbar__logo bg-white bg-opacity-80 p-4 mx-auto'>
          <Img Image={logo} className=''></Img>
        </Link>
      </div>

      <div className={`mt-4 cluster-l z-20`} style={{
        '--cSpace': "var(--s-2)",
        '--justify': 'center'
      }}>
        <a
          href={`https://bookings.travelclick.com/95899#/guestsandrooms`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Book Now"
          className="text-lg text-white btn btn-primary">
          Book Now
        </a>

        <a
          href={`https://www.opentable.com/restref/client/?rid=218656&restref=218656`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="OpenTable Booking Link"
          className="text-lg text-white btn btn-primary-outline">
          OpenTable
        </a>
      </div>
    </div>

    <div className={`bg-white text-black p-4 py-8 mobile-nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 icon m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <ul className="navbar__content stack-l uppercase" style={{ "--space": "var(--s2)" }}>
        <li>
          <Link className={pathname === '/' && 'active'} to="/" onClick={() => {
            setIsOpen(false);
          }}>
            <Img Image={logo}></Img>
          </Link>
        </li>
        <li>
          <Link className={pathname === '/rooms' && 'active'} to="/rooms" onClick={() => {
            setIsOpen(false);
          }}>
            Rooms
          </Link>
        </li>

        <li>
          <Link className={pathname === '/gallery/main' && 'active'} to="/gallery/main" onClick={() => {
            setIsOpen(false);
          }}>
            Photo Gallery
          </Link>
        </li>
        <li>
          <Link className={pathname === '/specials' && 'active'} to="/specials" onClick={() => {
            setIsOpen(false);
          }}>
            Specials & Events
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' && 'active'} to="/dining" onClick={() => {
            setIsOpen(false);
          }}>
            Dining & Tavern
          </Link>
        </li>
        <li>
          <Link className={pathname === '/things-to-do' && 'active'} to="/things-to-do" onClick={() => {
            setIsOpen(false);
          }}>
            Things to Do
          </Link>
        </li>
        <li>
          <Link className={pathname === '/weddings' && 'active'} to="/weddings" onClick={() => {
            setIsOpen(false);
          }}>
            Weddings & Meetings
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' && 'active'} to="/about" onClick={() => {
            setIsOpen(false);
          }}>
            About
          </Link>
        </li>
        <li>
          <Link className={pathname === '/contact' && 'active'} to="/contact" onClick={() => {
            setIsOpen(false);
          }}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  const { logo } = CoreData;

  return (
    <React.Fragment>
      <div className="hide-on-md z-50 relative">
        <DesktopNavbar CoreData={CoreData} />
      </div>
      <div className="block md:hidden">
        <MobileNavbar CoreData={CoreData} />
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavBar);