import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation, withRouter } from 'react-router-dom';
import Navbar from './container/NavBar';
// import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';

import { convertNullToUndefined } from './helpers';
import { EmailSignUpForm, CompleteGoogleReCaptchaProvider, SimpleContactForm } from 'component/ContactForm';

const HomePage = React.lazy(() => import('./container/Home'));
const RoomPage = React.lazy(() => import('./container/Rooms'));
const HeroIntroSections = React.lazy(() => import('./container/HeroIntroSections'));
const HeroIntroPanels = React.lazy(() => import('./container/HeroIntroPanels'));
const GalleryPage = React.lazy(() => import('./container/Gallery'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const ContactPage = React.lazy(() => import('./container/ContactPage'));
const CustomPage = React.lazy(() => import('./container/CustomPage'));
const EventPage = React.lazy(() => import('./container/Events'));

function App({ history }) {
  const [data, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(convertNullToUndefined(data));
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      });

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Home = {}, CoreData = {}, Rooms = {}, Specials = {}, Weddings = {}, Dining = {}, ThingsToDo = {}, About = {}, Events = [] } = data;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {/* {Popup && !Popup.Disabled && <PopupControl key={location.pathname} cmsData={Popup}></PopupControl>} */}
        <Navbar CoreData={CoreData} />
        <Switch>

          <Route exact path="/rooms" component={() => <>
            <RoomPage cmsData={Rooms} />
          </>} />

          <Route exact path="/specials" component={() => <>
            <HeroIntroSections cmsData={Specials} />
          </>} />

          <Route exact path="/weddings" component={() => <>
            <HeroIntroSections cmsData={Weddings} />
          </>} />

          <Route exact path="/things-to-do" component={() => <>
            <HeroIntroPanels cmsData={ThingsToDo} />
          </>} />

          <Route exact path="/about" component={() => <>
            <HeroIntroPanels cmsData={About} />
          </>} />



          <Route exact path="/rooms" component={() => <>
            <RoomPage cmsData={Rooms} />
          </>} />

          <Route exact path="/dining" component={() => <>
            <DiningPage cmsData={Dining} />
          </>} />

          <Route exact path="/contact" component={() => <>
            <ContactPage />
          </>} />

          <Route exact path="/calendar" component={() => <>
            <EventPage Events={Events} />
          </>} />

          <Route exact path="/gallery/:name" component={GalleryPage} />

          <Route path="/pages/:name" component={CustomPage} />

          <Route path="/" component={() =>
            <>
              <HomePage cmsData={Home} />
            </>} />
        </Switch>


        <CompleteGoogleReCaptchaProvider>
          <Switch>
            <Route exact path="/weddings" component={() => <SimpleContactForm bookingType='Weddings' heading='Weddings & Meetings Contact Form'></SimpleContactForm>} />
            <Route exact path="/specials" component={() => <SimpleContactForm bookingType='Specials' heading='Specials & Events Contact Form'></SimpleContactForm>} />
            <Route path="/" component={EmailSignUpForm} />
          </Switch>
        </CompleteGoogleReCaptchaProvider>

        <Footer cmsData={CoreData} />
        {/* <FavouriteManager CoreData={CoreData} Rooms={Rooms.Rooms} LMS={Specials.LastMinuteSpecials} Packages={Specials.Promotions} /> */}
      </div>
    </Suspense>
  );
}

export default withRouter(App);
