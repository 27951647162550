import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useFavouriteContext, actions } from 'context/favourite';
import Modal from '../../component/Modal';
import StrapiAPI from 'api/StrapiAPI';

export const CompleteGoogleReCaptchaProvider = ({ children }) => {
    return <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        {children}
    </GoogleReCaptchaProvider>
}

export const GroupContactForm = (() => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);
    const { dispatch, state } = useFavouriteContext();
    const { user } = state;

    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            emailAddress: user.emailAddress || '',
        }
    });

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const hasUser = !!user.emailAddress;
        if (!hasUser) {
            const { emailAddress, firstName, lastName } = data;
            localStorage.setItem('emailAddress', emailAddress);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            dispatch(actions.updateUser(emailAddress, firstName, lastName));
        }

        const cleanedData = { ...data, captchaResponse }

        console.log(cleanedData)
        const { success } = await StrapiAPI.sendContactUs(cleanedData, 'Groups, Weddings, Reunions')
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <section className="border border-primary-blue py-4">
            <div>
                <h1 className="text-center mb-8">Groups, Weddings, Reunions</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>First Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                        {errors.firstName && <div className="error">First Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Last Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Email Address</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                        {errors.emailAddress && <div className="error">Email is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Phone Number</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="cellPhone" placeholder="Phone Number" />
                        {errors.cellPhone && <div className="error">Phone Number is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Preferred Dates</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="dateDesired" placeholder="Dates Desired" />
                        {errors.dateDesired && <div className="error">Preferred Dates is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Number of Rooms</label>
                        <input ref={register({ required: true, min: 0 })} className="w-full py-2 border-b border-gray-600" type="number" name="numberOfRooms" placeholder="Number of Rooms" />
                        {errors.numberOfRooms && <div className="error">Number of Rooms is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full inline-block">
                        <label>How can we help you?</label>
                        <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="questions" placeholder="How can we help you?" />
                    </div>
                    <div className="mb-8 px-4 w-full inline-block flex justify-center items-center">
                        <input type="checkbox" id="isSubscribed" name="isSubscribed" ref={register()} defaultChecked />
                        <label htmlFor="isSubscribed" className="ml-2">Subscribe to our promotional emails</label>
                    </div>
                    <div className="mx-4 inline-block flex flex-col items-center">
                        <button type="submit" className="bg-primary-blue text-white w-full px-8 py-4">Submit</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}>
                <div className="p-8 bg-white max-w-xl mx-auto">
                    {success ?
                        <p>Thank you for contacting us! We'll be in touch soon!</p> :
                        <p>Something went wrong, please try submitting again or calling our number!</p>
                    }
                </div>
            </Modal>
        </section>
    );
});

export const SimpleContactForm = (({ heading = 'Contact Us', bookingType = 'General' }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);
    const { dispatch, state } = useFavouriteContext();
    const { user } = state;

    const { register, handleSubmit, errors, reset, formState: { isSubmitting } } = useForm({
        defaultValues: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            emailAddress: user.emailAddress || '',
        }
    });

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const hasUser = !!user.emailAddress;
        if (!hasUser) {
            const { emailAddress, firstName, lastName } = data;
            localStorage.setItem('emailAddress', emailAddress);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            dispatch(actions.updateUser(emailAddress, firstName, lastName));
        }

        const cleanedData = { ...data, captchaResponse }

        console.log(cleanedData)
        const { success } = await StrapiAPI.sendContactUs(cleanedData, bookingType)
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <section className='py-4'>
            <div>
                <h3 className="h1 text-center mb-8">{heading}</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>First Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                        {errors.firstName && <div className="error">First Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Last Name</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Email Address</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                        {errors.emailAddress && <div className="error">Email is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                        <label>Phone Number</label>
                        <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="cellPhone" placeholder="Phone Number" />
                        {errors.cellPhone && <div className="error">Phone Number is required</div>}
                    </div>
                    <div className="mb-8 px-4 w-full inline-block">
                        <label>How can we help you?</label>
                        <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="questions" placeholder="How can we help you?" />
                    </div>
                    <div className="mb-8 px-4 w-full inline-block flex justify-center items-center">
                        <input type="checkbox" id="isSubscribed" name="isSubscribed" ref={register()} defaultChecked />
                        <label htmlFor="isSubscribed" className="ml-2">Subscribe to our promotional emails</label>
                    </div>
                    <div className="mx-4 inline-block flex flex-col items-center">
                        <button type="submit" disabled={isSubmitting} className="btn-primary uppercase btn-lg w-full">Submit</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}>
                <div className="p-8 bg-white max-w-xl mx-auto">
                    {success ?
                        <p>Thank you for contacting us! We'll be in touch soon!</p> :
                        <p>Something went wrong, please try submitting again or calling our number!</p>
                    }
                </div>
            </Modal>
        </section>
    );
});

export const EmailSignUpForm = (() => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(null);
    const { dispatch, state } = useFavouriteContext();
    const { user } = state;

    const { register, handleSubmit, errors, reset, formState: { isSubmitting } } = useForm({
        defaultValues: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            emailAddress: user.emailAddress || '',
        }
    });

    const onSubmit = async (data) => {
        const captchaResponse = await executeRecaptcha('contact');

        const hasUser = !!user.emailAddress;
        if (!hasUser) {
            const { emailAddress, firstName, lastName } = data;
            localStorage.setItem('emailAddress', emailAddress);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            dispatch(actions.updateUser(emailAddress, firstName, lastName));
        }

        const cleanedData = { ...data, captchaResponse, isSubscribed: true };

        const { success } = await StrapiAPI.sendContactUs(cleanedData, 'Email Sign Up')
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return <section id="email-signup" className="stack-l md:px-4 py-8">
        <h3 className="text-center">SIGN UP FOR UPDATES!</h3>
        <p className="text-center">Get news, events and menus from Three Chimneys Inn & ffrost Sawyer Tavern in your inbox.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                <label>First Name</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                {errors.firstName && <div className="error">First Name is required</div>}
            </div>
            <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                <label>Last Name</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                {errors.lastName && <div className="error">Last Name is required</div>}
            </div>
            <div className="mb-8 px-4 w-full inline-block">
                <label>Email Address</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
                {errors.emailAddress && <div className="error">Email is required</div>}
            </div>
            <p className='mx-4 text-sm'>
                By submitting this form, you are consenting to receive null from: Three Chimneys Inn & ffrost Sawyer Tavern, http://www.threechimneysinn.com. You can revoke your consent to receive emails at any time by using the SafeUnsubscribe® link, found at the bottom of every email. Emails are serviced by <a href="https://www.constantcontact.com/legal/service-provider" target='_blank'>
                    Constant Contact.
                </a>
            </p>
            <div className="mx-4 inline-block flex flex-col items-center" style={{ "marginTop": "auto" }}>
                <button type="submit" disabled={isSubmitting} className="bg-primary-blue text-white w-full px-8 py-4">Submit</button>
            </div>
        </form>
        <Modal
            isOpen={success !== null}
            onClose={() => {
                setSuccess(null);
            }}>
            <div className="p-8 bg-white max-w-xl mx-auto">
                {success ?
                    <p>Thank you for subscribing! We'll be in touch soon!</p> :
                    <p>Something went wrong, please try submitting again or calling our number!</p>
                }
            </div>
        </Modal>
    </section>
});
