import { convertNullToUndefined } from 'helpers';
import { fetchGet, fetchPost } from './helper';


export default class {
    static async getDailySpecials() {
        const data = await fetchGet(`${process.env.REACT_APP_API_DOMAIN}/fb-all`);
        return data || [];
    }

    static async getLocationSpecials(location) {
        const data = await fetchGet(`${process.env.REACT_APP_API_DOMAIN}/fb-${location}`);
        return data || [];
    }

    static async getAllergiesData() {
        const data = await fetchGet(`${process.env.REACT_APP_API_DOMAIN}/allergies`);
        return convertNullToUndefined(data);
    }

    static sendContactUs(data, bookingType = 'General') {
        const { firstName, lastName, emailAddress, cellPhone = '', city = '', groupName = '', dateDesired = '', numberOfRooms = 0, questions = '', isSubscribed = false, captchaResponse } = data;

        const body = {
            firstName,
            lastName,
            emailAddress,
            cellPhone,
            city,
            groupName,
            dateDesired,
            numberOfRooms,
            questions,
            bookingType,
            isSubscribed,
            captchaResponse
        }
        return fetchPost(`${process.env.REACT_APP_API_DOMAIN}/contacts`, body);
    }
}
