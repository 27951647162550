import React, { useEffect } from 'react';
import "./style.css";
import { IoMdClose } from 'react-icons/io';

export default ({ children, isOpen, onClose, isShowingClose = true, innerClasses = "", isAnimateBottom }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const handleBackdropClose = (e) => {
        if (e.target === e.currentTarget && onClose) {
            onClose();
        }
    }

    return (
        <dialog onClick={handleBackdropClose} open={isOpen} className={`fixed inset-0 w-full z-50 bg-opacity-50 bg-black component-modal-backdrop p-0 flex ${isOpen ? 'active' : ''}`}>
            <div onClick={handleBackdropClose} className={`component-modal ${isAnimateBottom ? 'animate-bottom' : ''} ${innerClasses} ${isOpen ? 'active' : ''}`}>
                {children}
            </div>
            {isShowingClose && <button
                aria-label="Close"
                className="absolute pointer-cursor m-8 top-0 right-0 text-white text-4xl"
                onClick={onClose}
            >
                <IoMdClose />
            </button>}
        </dialog>
    );
}