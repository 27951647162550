import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { AiFillFacebook } from 'react-icons/ai';
import { FaInstagram, FaTripadvisor, FaTwitterSquare } from 'react-icons/fa';

import { Img } from "component/FrameL";

import BespokeLogo from "assets/bespoke_footer_icon.png";
import PhanthomGourment from "assets/phantom-gourmet.png";
import TheKnot from "assets/the-knot.png";
import DinersChoice from "assets/diners-choice.jpeg";
import BBB from "assets/bbb-accredited.png";

import './style.css';

const Footer = ({ cmsData }) => {
    const { brandName, phone, facebook, instagram, addressLine1, addressLine2, tripAdvisor, twitter } = cmsData;
    return <>
        <footer className="relative bg-cover py-8 px-4 md:px-8 z-0 stack-l text-white bg-primary-blue text-center" style={{ '--space': 'var(--s2)' }}>
            <div className='max-w-screen-md mx-auto flex flex-row gap-8 items-center'>
                <div>
                    <Img src={DinersChoice} isExternal={false}></Img>
                </div>
                <div>
                    <Img src={BBB} isExternal={false}></Img>
                </div>
                <div>
                    <Img src={TheKnot} isExternal={false}></Img>
                </div>
                <div>
                    <Img src={PhanthomGourment} isExternal={false}></Img>
                </div>
            </div>

            <div>
                <div className='cluster-l uppercase' style={{ "--cSpace": "var(--s1)", "--justify": "center" }}>
                    <Link to="/news" className='text-white no-underline'>News</Link>
                    <Link to="/calendar" className='text-white no-underline'>Calendar</Link>
                    {/* <Link to="/location" className='text-white no-underline'>Location</Link> */}
                    <Link to="/pages/history" className='text-white no-underline'>Our History</Link>
                    <Link to="/contact" className='text-white no-underline'>Contact</Link>
                </div>
            </div>

            <div>
                <div className='stack-l' style={{ '--space': '0' }}>
                    <p>
                        {phone}
                    </p>
                    <p>
                        {addressLine1}, {addressLine2}
                    </p>
                    <p>
                        © {new Date().getFullYear()} {brandName}
                    </p>
                </div>
            </div>

            <div className="flex flex-row items-center justify-center text-5xl gap-2">
                <a href={instagram} className='text-white' target="_blank" aria-label="Instagram Link">
                    <FaInstagram></FaInstagram>
                </a>
                <a href={facebook} className='text-white' target="_blank" aria-label="Facebook Link">
                    <AiFillFacebook></AiFillFacebook>
                </a>
                <a href={twitter} className='text-white' target="_blank" aria-label="Twitter Link">
                    <FaTwitterSquare></FaTwitterSquare>
                </a>
                <a href={tripAdvisor} className='text-white' target="_blank" aria-label="Trip Advisor Link">
                    <FaTripadvisor></FaTripadvisor>
                </a>
            </div>

            <div className='relative sm:absolute m-4 bottom-0 right-0'>
                <a href="https://bespoke.house" target='_blank'>
                    <Img className='mx-auto' isExternal={false} src={BespokeLogo} alt="Bespoke Logo" />
                </a>
            </div>
        </footer>
    </>;
};

export default Footer;
